import React, { useEffect, useState } from "react";
import AppHeader from "../../components/header";
import Dropdown from "react-bootstrap/Dropdown";
import Collapsible from "../../components/sections/CollapsibleFilter/CollapsibleFilter";
import GridView from "../../components/ItemsCardView/ItemsCardGridView";
import ListView from "../../components/ItemsCardView/ItemsCardListView";
import Pagination from "../../components/pagination/Pagination";
import TextSection from "../../components/sections/BottomTextSetion/BottomTextSetion";
import ListIcon from "../../assets/images/icons/icon-list.svg";
import QuickFilters from "../../components/sections/QuickFilters";
import FilterIcon from "../../assets/images/icons/Bold-Filter.svg";
import Footer from "../../components/footer";
import "./styles.scss";
import {
    getAppConfig,
    getHotels,
    setActivityIndicator,
    setPackageTypesState,
    setPagination,
    setResponseType,
    setShowRectangular,
} from "../../redux/slices/appconfigSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    getSearchFilters,
    setAppFilters,
    setDepartureFrom,
    setDesireCountry,
    setFlightCabinClass,
    setNumberOfStops, setPriceRangeValue,
    setSelectedPackage as _setSelectedPackage,
    setTravelDate,
    setTravelTime,
} from "../../redux/slices/searchFilterSlice";
import AppConfig from "../../helpers/config";
import { getSearchOnlyListingParams } from "../../helpers/common";
import { useLocation, useNavigate } from "react-router-dom";
import DesireCountryModal from "../../components/Modals/DesireCountryModal";
import DepartureFromModal from "../../components/Modals/DepartureFromModal";
import moment from "moment";
import { getLoginConfig } from "../../redux/slices/loginconfigSlice";
import { GetPackageTypesService } from "../../services/HomePackageTypesSevice";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import GridIcon from "../../assets/images/icons/grid-icon.svg";
import RangeBar from "../../components/slider/RangeBar";
import ScrollLock from "../../components/ScrollLock";
import useTranslationCustom from "../../i18n/customHook/useTranslation";

const Search = () => {
    const url = window.location.href;
    const objUrl = new URL(url);
    const { T } = useTranslationCustom();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(objUrl.search);
    const pathName = location.pathname;
    const appconfig = useSelector(getAppConfig);
    const loginConfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const packageGuid = pathName.split("/search/").pop().split("/query")[0];
    const [showFilters, setShowFilters] = useState(false);
    const [justArrived, setJustArrived] = useState(true);
    const [page, setPage] = useState(Number(searchParams.get("page")));
    const [modals, setModals] = useState({
        destination: false,
        departure: false,
    });
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [dateShouldReset, setDateShouldReset] = useState(false);
    const toggleFilter = () => setShowFilters(true);
    const hideFilter = () => setShowFilters(false);

    // It will set the page number
    useEffect(() => {
        setPage(Number(searchParams.get("page")));
    }, [url]);

    // update selectedPackageLocally
    useEffect(() => {
        if (packageGuid === searchfilters.selectedPackage?.guid) {
            setSelectedPackage(searchfilters.selectedPackage);
        }
    }, [searchfilters.selectedPackage]);

    useEffect(() => {
        // If no desired location selected then continue with pre-fetched.
        if (
            searchParams.get("locale") === loginConfig.locale.code &&
            searchParams.get("currency") === loginConfig.currency.code
        ) {
            GetPackageTypes(false); // Normal get packages function
        } else {
            GetPackageTypesWithNewGUIDs(); // If locale changes somewhere in the app and url are different then we need to update GUIDs for it.
        }
    }, []);

    // ** TESTED **
    const GetPackageTypes = (justPackagesUpdate = false) => {
        GetPackageTypesService(searchfilters, loginConfig)
            .then((_packageTypes) => {
                const HomeGUID = _packageTypes.filter((p) => p.type === "Home")[0].guid;
                let filteredPackages = _packageTypes.filter((p) => p.type !== "Home");
                dispatch(setPackageTypesState(filteredPackages));

                if (justPackagesUpdate) {
                    return;
                }

                const index = filteredPackages.findIndex((f) => f.guid === packageGuid);

                if (index !== -1) {
                    dispatch(_setSelectedPackage(filteredPackages[index]));
                }

                const params = getSearchOnlyListingParams({
                    guid: packageGuid,
                    page: Number(searchParams.get("page")),
                    searchfilters,
                    loginconfig: loginConfig,
                    initialSearch: true,
                    SearchType: HomeGUID === packageGuid ? 'Home' : 'Tile'
                });

                dispatch(getHotels(params));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setActivityIndicator(false));
            });
    };

    const GetPackageTypesWithNewGUIDs = () => {
        GetPackageTypesService(searchfilters, loginConfig)
            .then((_packageTypes) => {
                let GUIDForPayload = "";

                let filteredPackages = _packageTypes.filter((p) => p.type !== "Home");
                dispatch(setPackageTypesState(filteredPackages));

                let homePackage = _packageTypes.find((p) => p.type === "Home");
                GUIDForPayload = homePackage?.guid;
                dispatch(_setSelectedPackage(null));
                dispatch(setTravelDate({ startDate: "", endDate: "" }));
                setDateShouldReset(true);

                navigate(
                    "/search/" +
                    GUIDForPayload +
                    "/query?page=1&locale=" +
                    loginConfig.locale.code +
                    "&currency=" +
                    loginConfig?.currency?.code
                );

                const params = getSearchOnlyListingParams({
                    guid: GUIDForPayload,
                    page: 1,
                    searchfilters,
                    loginconfig: loginConfig,
                    selectedPackage,
                    initialSearch:
                        !selectedPackage &&
                        !searchfilters.selectedDesireCountry &&
                        !searchfilters.departureFrom?.length,
                    bigSearch: searchfilters.appFilters?.length,
                    SearchType: 'Free'
                });

                dispatch(getHotels(params));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setActivityIndicator(false));
            });
    };

    // Get other packages for response type RealTime
    useEffect(() => {
        if (appconfig.responseType === "RealTime" && appconfig.offers.length > 0) {
            setTimeout(() => {
                navigate(
                    "/search/" +
                    appconfig.correlationId +
                    "/query?page=1&locale=" +
                    loginConfig.locale.code +
                    "&currency=" +
                    loginConfig?.currency?.code
                );
                const params = getSearchOnlyListingParams({
                    guid: appconfig?.correlationId,
                    page: 1,
                    searchfilters,
                    loginconfig: loginConfig,
                    selectedPackage,
                    bigSearch: searchfilters.appFilters?.length,
                    SearchType: 'Free',
                });
                dispatch(setResponseType(null));
                dispatch(getHotels(params));
            }, 1000);
        }
    }, [appconfig.responseType]);

    useEffect(() => {
        if (!justArrived && page > 1) {
            const params = getSearchOnlyListingParams({
                guid: appconfig?.correlationId,
                page,
                searchfilters,
                loginconfig: loginConfig,
                initialSearch: true,
                SearchType: 'Free'
            });

            dispatch(getHotels(params));
        }
    }, [page]);

    // This hook will take care if locale or currency changes.
    useEffect(() => {
        if (!justArrived) {
            if (searchfilters.selectedDesireCountry &&
                searchfilters.travelDate?.startDate &&
                searchfilters.travelDate?.endDate) {
                GetPackageTypes(true);
                const params = getSearchOnlyListingParams({
                    guid: "",
                    page: 1,
                    searchfilters,
                    loginconfig: loginConfig,
                    bigSearch: searchfilters.appFilters?.length,
                    SearchType: 'Free'
                });
                dispatch(getHotels(params));
            } else {
                GetPackageTypesWithNewGUIDs();
            }
        } else {
            setJustArrived(false);
        }
    }, [loginConfig.currency]);

    // This hook will update the url if correlational ID changes
    useEffect(() => {
        if (packageGuid !== appconfig?.correlationId) {
            navigate(
                "/search/" +
                appconfig.correlationId +
                "/query?page=1&locale=" +
                loginConfig.locale.code +
                "&currency=" +
                loginConfig?.currency?.code,
                { replace: true }
            );
        }
    }, [appconfig?.correlationId]);

    // Only one destination will be selected at a time so following two hooke will null other destination if one selected.
    useEffect(() => {
        if (searchfilters.selectedDesireCountry !== null && !justArrived) {
            setSelectedPackage(null);
        }
    }, [searchfilters.selectedDesireCountry]);

    useEffect(() => {
        if (selectedPackage !== null && !justArrived) {
            dispatch(setDesireCountry(null));
        }
        if (justArrived && packageGuid === selectedPackage?.guid) {
            dispatch(setDesireCountry(null));
        }
    }, [selectedPackage]);
    // End of selection hooks.

    const handleCheckBox = (e, item, multiple) => {
        let _filters = [...searchfilters.appFilters] || [];
        if (e?.target?.checked || e === true) {
            if (item.key) {
                const index = _filters.findIndex((f) => f.key === item.key);
                if (index !== -1 && !multiple) {
                    _filters[index] = {
                        _id: `checkbox-${item.id}-${item.text}-${item?.key}`,
                        ...item,
                    };
                } else {
                    _filters.push({
                        _id: `checkbox-${item.id}-${item.text}-${item?.key}`,
                        ...item,
                    });
                }
            } else {
                _filters.push({
                    _id: `checkbox-${item.id}-${item.text}-${item?.key}`,
                    ...item,
                });
            }

            dispatch(setAppFilters(_filters));
        } else {
            handleDeleteFilter(`checkbox-${item.id}-${item.text}-${item?.key}`);
        }
        if (item?.key === "departure_date" && e?.target?.checked === true) {
            dispatch(
                setTravelDate({
                    startDate: new Date(
                        moment(searchfilters.travelDate.startDate).subtract("days", item.id)
                    ),
                    endDate: new Date(
                        moment(searchfilters.travelDate.endDate).add("days", item.id)
                    ),
                })
            );
        } else if (item?.key === "departure_date" && e?.target?.checked === false) {
            dispatch(
                setTravelDate({
                    startDate: new Date(
                        moment(searchfilters.travelDate.startDate).add("days", item.id)
                    ),
                    endDate: new Date(
                        moment(searchfilters.travelDate.endDate).subtract("days", item.id)
                    ),
                })
            );
        }
    };

    const handleDeleteFilter = (id) => {
        let _filters = [...searchfilters.appFilters];
        dispatch(setAppFilters(_filters?.filter((f) => f._id !== id)));
    };

    const handleSearchFromScratch = (e) => {
        e.preventDefault();

        window.scroll(0, 0);

        if (!selectedPackage && !searchfilters.selectedDesireCountry) {
            return toast.info(T("Toasts:Please_Choose_Package_Destination"));
        }

        if (!searchfilters?.departureFrom?.length) {
            return toast.info(T("Toasts:Please_Choose_Departure"));
        }

        if (
            !searchfilters.travelDate.startDate ||
            !searchfilters.travelDate.endDate
        ) {
            return toast.info(T("Toasts:Please_Select_Date_Range"));
        }

        dispatch(setPagination(null));

        const params = getSearchOnlyListingParams({
            guid: "",
            page: 1,
            searchfilters,
            loginconfig: loginConfig,
            selectedPackage,
            bigSearch: true,
            SearchType: 'Free'
        });

        console.log({ params });

        dispatch(getHotels(params));
        hideFilter();
    };

    const SortBySection = () => {
        return (
            <>
                <div className="list-grid-toggler">
                    <a
                        onClick={() =>
                            dispatch(setShowRectangular(!appconfig.showRectangular))
                        }
                    >
                        <img
                            src={appconfig.showRectangular ? GridIcon : ListIcon}
                            alt={"list-icon"}
                        />
                    </a>
                </div>
            </>
        );
    };

    const handlePriceChange = ({ low, high }) => {
        dispatch(setPriceRangeValue({ low, high }));
    };

    return (
        <>
            <div className="main-wrapper search-page">
                <AppHeader />
                <main className="body-wrapper">
                    {/*Quick filters section*/}
                    <div id="quickSearch" className="quick-search-wrapper">
                        <div className="container">
                            <QuickFilters
                                searchPageComponent={true}
                                dateShouldReset={dateShouldReset}
                                setDateShouldReset={setDateShouldReset}
                            />
                        </div>
                    </div>
                    {/*End Quick filters section*/}

                    <div id="result" className="search-result-wrapper">
                        <div className="container">
                            {/*offers count section*/}
                            <div className="row for-small-screen">
                                <div className="col-12">
                                    <div className="top-caption">
                                        <h5 className="">
                                            {T("Filters:Filters_Packages_Found", { count: appconfig?.metaDataCount || 0 })}
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="filter-holder">
                                        <div className="row hide-on-small-screen">
                                            <div className="col-lg-9 col-md-8 col-12">
                                                <ul className="search-tags">
                                                    {searchfilters?.appFilters?.map((filter, index) => {
                                                        return (
                                                            <li key={index}>
                                                                {filter?.text}
                                                                <a
                                                                    onClick={() => handleDeleteFilter(filter?._id)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faTimes}
                                                                        className="close-icon"
                                                                    />
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                    {searchfilters.appFilters?.length ? (
                                                        <li className="reset-all">
                                                            <a onClick={() => dispatch(setAppFilters([]))}>
                                                                {T("Buttons:Reset_All")}
                                                                <FontAwesomeIcon
                                                                    icon={faTimes}
                                                                    className="close-icon"
                                                                />
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-12">
                                                <div className="filter-action">
                                                    <SortBySection />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-mob-wrapper show-on-small-screen">
                                            <div className="filter-toggles ">
                                                <a onClick={toggleFilter}>
                                                    {" "}
                                                    <img src={FilterIcon} alt={"filter-icon"} />{" "}
                                                    {T("Filters:Filters_Text")}
                                                </a>
                                                <span className="count">
                                                    {searchfilters.appFilters?.length}
                                                </span>
                                            </div>

                                            <SortBySection />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end section*/}

                            <div className="row">
                                <ScrollLock isOpen={true}>
                                    <div className="col-xl-3 col-lg-4">
                                        <form
                                            className={
                                                showFilters
                                                    ? "collapsable-filter-wrapper open-filter"
                                                    : "collapsable-filter-wrapper"
                                            }
                                        >
                                            {/*selected filters by side left view*/}
                                            <div className="filter-header show-on-small-screen">
                                                <div className="filter-title-area">
                                                    <a onClick={hideFilter} className="close-icon">
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </a>
                                                    <h6>
                                                        {" "}
                                                        {T("Filters:Filters_Text")}{" "}
                                                        <span className="count">
                                                            {searchfilters.appFilters.length}
                                                        </span>{" "}
                                                    </h6>
                                                    <a onClick={() => dispatch(setAppFilters([]))}
                                                        className="delete-all-link">
                                                        {T("Buttons:Delete_All")}{" "}
                                                    </a>
                                                </div>
                                                <ul className="search-tags">
                                                    {searchfilters.appFilters?.map((filter, index) => {
                                                        return (
                                                            <li key={index}>
                                                                {filter.text}
                                                                <a onClick={() => handleDeleteFilter(filter._id)}>
                                                                    <FontAwesomeIcon
                                                                        icon={faTimes}
                                                                        className="close-icon"
                                                                    />
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            {/*End of section*/}

                                            <div className="collapsable-block">
                                                <div className="rangeslider">
                                                    <RangeBar
                                                        lowPrice={searchfilters.defaultPrice.low}
                                                        highPrice={searchfilters.defaultPrice.high}
                                                        onChangePriceHandler={handlePriceChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="collapsable-block">
                                                <h6 className="heading fs-14 medium">
                                                    {T("Filters:Destinations")}
                                                </h6>

                                                <div className="form-group">
                                                    <FontAwesomeIcon
                                                        icon={faMapMarkerAlt}
                                                        className="floating-icon"
                                                    />
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        name="desireLocation"
                                                        value={
                                                            searchfilters?.selectedDesireCountry?.keyword ||
                                                            T("Filters:Choose_Destination")
                                                        }
                                                        onClick={() =>
                                                            setModals({
                                                                ...modals,
                                                                destination: !modals.destination,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="collapsable-block">
                                                <h6 className="heading fs-14 medium">
                                                    {T("Filters:Departure_From")}
                                                </h6>
                                                <div className="form-group">
                                                    <FontAwesomeIcon
                                                        icon={faMapMarkerAlt}
                                                        className="floating-icon"
                                                    />
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        name="desireLocation"
                                                        value={T("Filters:Filter_Departure_Placeholder")}
                                                        onClick={() =>
                                                            setModals({
                                                                ...modals,
                                                                departure: !modals.departure,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <ul className="loaction-tag">
                                                    {searchfilters.departureFrom?.map(
                                                        (_departure, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            setDepartureFrom(
                                                                                searchfilters.departureFrom?.filter(
                                                                                    (f) => f.code !== _departure.code
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    {`${_departure?.code} ${_departure?.name}`}{" "}
                                                                    <span>x</span>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>

                                            <div className="collapsable-block">
                                                <Collapsible
                                                    title={T("Filters:Filters_Hotel_Rating")}
                                                    _activeKey={'rating'}
                                                    items={AppConfig.guestRating}
                                                    handleClickEvent={handleCheckBox}
                                                />
                                            </div>

                                            <div className="collapsable-block">
                                                <Collapsible
                                                    title={T("Filters:Filters_Stops")}
                                                    _activeKey={'stops'}
                                                    handleClickEvent={(e, item) => {
                                                        dispatch(setNumberOfStops(item?.id));
                                                        handleCheckBox(e, item);
                                                    }}
                                                    items={AppConfig.steps}
                                                />
                                            </div>
                                            <div className="collapsable-block">
                                                <Collapsible
                                                    title={T("Filters:Filters_Flight_Departure_Times")}
                                                    _activeKey={'departure-time'}
                                                    handleClickEvent={(e, item) => {
                                                        dispatch(
                                                            setTravelTime({
                                                                departureTime: e?.target?.checked
                                                                    ? item?.StartTime && item?.EndTime
                                                                        ? `${item.StartTime}-${item.EndTime}`
                                                                        : "Anytime"
                                                                    : null,
                                                                arrivalTime:
                                                                    searchfilters?.travelTime?.arrivalTime || null,
                                                            })
                                                        );
                                                        handleCheckBox(e, item);
                                                    }}
                                                    items={AppConfig.departureTime}
                                                />
                                            </div>
                                            <div className="collapsable-block">
                                                <Collapsible
                                                    title={T("Filters:Filters_Flight_Return_Times")}
                                                    _activeKey={'arrival-time'}
                                                    handleClickEvent={(e, item) => {
                                                        dispatch(
                                                            setTravelTime({
                                                                departureTime:
                                                                    searchfilters?.travelTime?.departureTime ||
                                                                    null,
                                                                arrivalTime: e?.target?.checked
                                                                    ? item?.StartTime && item?.EndTime
                                                                        ? `${item.StartTime}-${item.EndTime}`
                                                                        : "Anytime"
                                                                    : null,
                                                            })
                                                        );
                                                        handleCheckBox(e, item);
                                                    }}
                                                    items={AppConfig.arrivalTime}
                                                />
                                            </div>

                                            <div className="collapsable-block">
                                                <h6 className="heading fs-14 medium">
                                                    {T("Filters:Filters_Class")}
                                                </h6>
                                                <div className="form-group dropdown-with-checkbox">
                                                    <Dropdown>
                                                        <Dropdown.Toggle>
                                                            {searchfilters.appFilters?.find(
                                                                (f) => f?.key === "airline_class"
                                                            )?.text || T("Filters:Filers_Choose_Class")}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {AppConfig.airlineClasses.map((airline, index) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        key={index}
                                                                        onClick={() => {
                                                                            handleCheckBox(true, airline);
                                                                            dispatch(
                                                                                setFlightCabinClass(airline.value)
                                                                            );
                                                                        }}
                                                                        active={
                                                                            searchfilters.appFilters.find(
                                                                                (f) => f?.key === "airline_class"
                                                                            )?.id === airline?.id
                                                                        }
                                                                    >
                                                                        {airline?.text}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="filters-sidebar-btn">
                                                <a
                                                    className="apply-filter-btn"
                                                    onClick={handleSearchFromScratch}
                                                >
                                                    {T("Buttons:Search")}
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </ScrollLock>

                                <div className="col-xl-9 col-lg-8">
                                    <div className="hotal-item-wrapper grid">
                                        {appconfig.showRectangular ? <ListView /> : <GridView />}
                                    </div>

                                    <div className="custom-pagination">
                                        <Pagination
                                            _setPage={(page) => {
                                                let url = window.location.pathname;
                                                navigate(
                                                    url +
                                                    "?page=" +
                                                    page +
                                                    "&locale=" +
                                                    loginConfig.locale.code +
                                                    "&currency=" +
                                                    loginConfig?.currency?.code
                                                );
                                                setPage(page);
                                            }}
                                            page={page}
                                        />
                                    </div>

                                    <TextSection />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
            <DesireCountryModal
                show={modals.destination}
                onHide={() => setModals({ ...modals, destination: false })}
            />
            <DepartureFromModal
                show={modals.departure}
                onHide={() => setModals({ ...modals, departure: false })}
                onApplyButtonHandler={(values) => dispatch(setDepartureFrom(values))}
            />
        </>
    );
};

export default Search;
